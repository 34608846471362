import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';

import {Container, Row, Col} from 'reactstrap';

import Link from '../net/Link';

import moment from 'moment';
import DownloadLink from '../components/DownloadLink';
import { graphql } from 'gatsby';
import ArticleGraph from '../components/ArticleGraph';

class PageTemplate extends React.Component {

render() {
  const { data } = this.props;
  const { markdownRemark } = data;
  const { html, frontmatter } = markdownRemark; 

  let showBacklink = false;
  let showDate = false;
  let dateString = '';
  let backlinkText, backlinkLink;
  if (frontmatter.path.startsWith('/articles/') && frontmatter.path !== '/articles/') {
    showBacklink = true;
    showDate = true;
    backlinkText = 'To the Article Archive';
    backlinkLink = '/articles/';
  };
  if (frontmatter.date) {
    dateString = moment(frontmatter.date).format("YYYY~MM~DD");
  }

  // check if we should render the content - only when not in the future!
  let now = moment();

  let date = moment(frontmatter.date);
  let render = false;

  render = now.isSameOrAfter(date);

  let title = frontmatter.title;
  let abstract = frontmatter.abstract;
  let image = frontmatter.featureImage || "";

  if (! render) {
    title = "Nothing here.";
    abstract = "";
  }

  let downloadNodes = undefined;

  if (frontmatter.downloads) {
    downloadNodes = frontmatter.downloads.map(download => {
      return (
        <li key={download.to}><DownloadLink to={download.to} text={download.text}/></li>
      )
    });
  }

  return (

    <Layout>
      <SEO title={title} description={abstract} image={image} />
      
      <div>
        <Container fluid className="content-page block">
        
        {showBacklink ? 
        <Row>
          <Col>
        <Link to={backlinkLink} className="archivelink">{backlinkText}</Link>
          </Col>
        </Row>
        : null }
        <Row>
            <Col>
            { render ? 
              <div dangerouslySetInnerHTML={{ __html: html }} />
              :
              <div>There is nothing to see here.</div>
            }
            </Col>
          </Row>
          {downloadNodes ? 
            <Row>
              <Col>
                <ul>{downloadNodes}</ul>
              </Col>
            </Row>
          : null }
          {showDate ? 
          <Row>
            <Col><div className='mt-4'><em>Published {dateString}</em></div></Col>
          </Row>
        : null }
        {
          // if we're in the article space and not on mobile, show the
          // funny graph view.
          frontmatter.path.startsWith('/articles/') ?
          <div className="d-none d-sm-none d-md-block mt-4">
            <Row>
              <Col>
                <h2>Link Graph</h2>
                <p><em>Yeah, I know, the 2000s knocked and wanted to show you their ideas about knowledge navigation, but I really like those graphs, even if they are not the most practical instruments, plus I actually developed a network-based knowledge management system called 'Serendipity' back in the day, so please stop making fun of me.</em></p>
            
                <ArticleGraph active={frontmatter.path}/>
              </Col>
            </Row>
          </div>
            
          :
            null
        }
        
        </Container>
    </div>
      </Layout>
  )
}

}
export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        featureImage
        abstract
        downloads {
          to
          text
        }
      }
    }
  }
  `